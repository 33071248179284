<template>
  <v-container fluid>
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>LEAVE STATES</v-toolbar-title>

      <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title active-class="secondary--text">
          <v-tabs-slider color="secondary"></v-tabs-slider>

          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item">
        <v-card outlined tile>
          <LeaveApprovalRequest />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>


<script>
  import { defineComponent, provide, reactive, toRefs, watch } from "vue";
  import LeaveApprovalRequest from "@/components/Leave/LeaveApprovalRequest";
  export default defineComponent({
    components: { LeaveApprovalRequest },
    setup() {
      const data = reactive({
        tab: null,
        items: ["Pending", "Approved", "Rejected"],
        statusSelected: "",
      });

      const { tab, statusSelected } = toRefs(data);
      provide("leaveStatus", tab);

      watch(tab, (current) => {
        switch (current) {
          case 0:
            statusSelected.value = "Pending";
            break;

          case 1:
            statusSelected.value = "Approved";
            break;

          case 2:
            statusSelected.value = "Rejected";
            break;

          default:
            statusSelected.value = "Pending";
            break;
        }
      });

      provide("leaveStatus", statusSelected);
      return {
        ...toRefs(data),
      };
    },
  });
</script>
