var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.results,"hide-default-footer":"","disable-pagination":"","item-key":"id","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.user",fn:function({ value }){return [_c('v-avatar',{attrs:{"size":"30"}},[_c('img',{attrs:{"contain":"","src":value.avatar_url
              ? value.avatar_url
              : require('@/assets/download.jpg')}})]),_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(value.title_name))])]}},{key:"item.leave_dates",fn:function({ item }){return [_c('v-menu',{attrs:{"close-on-content-click":false,"offset-x":"","rounded":"0","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":""}},'v-btn',attrs,false),on),[_vm._v(" view dates ")])]}}],null,true)},[_c('v-sheet',{attrs:{"width":"600"}},[_c('v-toolbar',{attrs:{"elevation":"0","outlined":"","color":"secondary"}},[_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-toolbar-title',[_vm._v(_vm._s(item.user.title_name))]),_c('v-spacer'),_c('v-toolbar-title',[_vm._v(" "+_vm._s(item.leave_dates.length)+" "+_vm._s(item.leave_dates.length > 1 ? "days" : "day")+" ")])],1),_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","event-color":_vm.getEventColor,"type":"month","events":item.leave_dates.map((item) => ({
                name: 'Leave',
                start: new Date(`${item}T00:00:00`),
                end: new Date(`${item}T23:59:59`),
                color: 'blue-grey darken-1',
              }))},on:{"change":_vm.getStaffLeaveStatus},model:{value:(_vm.leaveCalendar),callback:function ($$v) {_vm.leaveCalendar=$$v},expression:"leaveCalendar"}}),(item.status == 'Pending')?_c('div',{staticClass:"d-flex justify-end pa-5"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.reveal = true;
                _vm.reason = 'Reject';}}},[_vm._v(" Reject ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"success"},on:{"click":function($event){_vm.reveal = true;
                _vm.reason = 'Approve';}}},[_vm._v(" Approve ")])],1):_vm._e()],1),_c('v-expand-transition',[_c('validation-observer',{ref:"observer",attrs:{"tag":"form"}},[_c('v-form',{ref:"form"},[(_vm.reveal)?_c('v-card',{staticClass:"transition-fast-in-fast-out v-card--reveal",staticStyle:{"height":"100%","z-index":"3"}},[_c('v-toolbar',{attrs:{"elevation":"0","color":_vm.reason == 'Reject' ? 'error' : 'success',"dark":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(` ${_vm.reason} Leave `)+" ")]),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"float-right"},[_vm._v(_vm._s(item.user.title_name))])],1),_c('v-card-text',{staticClass:"pb-0"},[_c('validation-provider',{attrs:{"name":"Comments","rules":"required|max:500"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{attrs:{"error-messages":errors,"outlined":"","label":"Comments","placeholder":"A maximum of 500 characters","hide-details":"auto","counter":"500","rows":"8.5"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})]}}],null,true)})],1),_c('v-footer',{attrs:{"app":""}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.reveal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary accent-4"},on:{"click":function($event){return _vm.replyLeaveBtn(item.id)}}},[_vm._v(" Confirm ")])],1)],1):_vm._e()],1)],1)],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }