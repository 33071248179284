<template>
  <v-container fluid class="pa-0">
    <v-data-table
      :headers="headers"
      :items="results"
      hide-default-footer
      disable-pagination
      class="elevation-0"
      item-key="id"
      :loading="loading"
    >
      <template #item.user="{ value }">
        <v-avatar size="30">
          <img
            contain
            :src="
              value.avatar_url
                ? value.avatar_url
                : require('@/assets/download.jpg')
            "
          />
        </v-avatar>
        <span class="pl-4">{{ value.title_name }}</span>
      </template>
      <template #item.leave_dates="{ item }">
        <v-menu
          :close-on-content-click="false"
          offset-x
          rounded="0"
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" depressed> view dates </v-btn>
          </template>
          <v-sheet width="600">
            <v-toolbar elevation="0" outlined color="secondary">
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="$refs.calendar.prev()"
              >
                <v-icon> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="$refs.calendar.next()"
              >
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>

              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title>{{ item.user.title_name }}</v-toolbar-title>
              <v-spacer />
              <v-toolbar-title>
                {{ item.leave_dates.length }}
                {{ item.leave_dates.length > 1 ? "days" : "day" }}
              </v-toolbar-title>
            </v-toolbar>

            <v-calendar
              ref="calendar"
              color="primary"
              :event-color="getEventColor"
              v-model="leaveCalendar"
              type="month"
              :events="
                item.leave_dates.map((item) => ({
                  name: 'Leave',
                  start: new Date(`${item}T00:00:00`),
                  end: new Date(`${item}T23:59:59`),
                  color: 'blue-grey darken-1',
                }))
              "
              @change="getStaffLeaveStatus"
            ></v-calendar>
            <div
              v-if="item.status == 'Pending'"
              class="d-flex justify-end pa-5"
            >
              <v-btn
                color="error"
                @click="
                  reveal = true;
                  reason = 'Reject';
                "
              >
                Reject
              </v-btn>
              <v-btn
                color="success"
                class="mx-2"
                @click="
                  reveal = true;
                  reason = 'Approve';
                "
              >
                Approve
              </v-btn>
            </div>
          </v-sheet>
          <v-expand-transition>
            <validation-observer ref="observer" tag="form">
              <v-form ref="form">
                <v-card
                  v-if="reveal"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%; z-index: 3"
                >
                  <v-toolbar
                    elevation="0"
                    :color="reason == 'Reject' ? 'error' : 'success'"
                    dark
                  >
                    <v-toolbar-title>
                      {{ ` ${reason} Leave ` }} </v-toolbar-title
                    ><v-spacer />
                    <v-toolbar-title class="float-right">{{
                      item.user.title_name
                    }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text class="pb-0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Comments"
                      rules="required|max:500"
                    >
                      <v-textarea
                        :error-messages="errors"
                        outlined
                        label="Comments"
                        placeholder="A maximum of 500 characters"
                        v-model="comment"
                        hide-details="auto"
                        counter="500"
                        rows="8.5"
                      ></v-textarea>
                    </validation-provider>
                  </v-card-text>
                  <v-footer app>
                    <v-btn text @click="reveal = false"> Cancel </v-btn>
                    <v-btn
                      :loading="loading"
                      color="primary accent-4"
                      @click="replyLeaveBtn(item.id)"
                    >
                      Confirm
                    </v-btn>
                  </v-footer>
                </v-card>
              </v-form>
            </validation-observer>
          </v-expand-transition>
        </v-menu>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import { defineComponent, inject, reactive, toRefs } from "vue";
  import { required, max } from "vee-validate/dist/rules";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import { extend, ValidationProvider, ValidationObserver } from "vee-validate";

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });
  extend("max", {
    ...max,
    //message: "Email must be valid",
  });

  export default defineComponent({
    components: { ValidationObserver, ValidationProvider },
    setup() {
      const data = reactive({
        observer: null,
        results: [],
        loading: false,
        headers: [
          { text: "STAFF NAME", value: "user" },
          { text: "CALENDAR", value: "calendar.academic_calendar" },
          { text: "LEAVE STATUS", value: "status" },
          { text: "LEAVE DATES", value: "leave_dates" },
        ],
        leaveCalendar: "",
        reason: "",
        reveal: false,
        comment: "",
      });
      const leaveStatus = inject("leaveStatus");

      const { staffPendingLeave, staffLeaveResponse } = useActions([
        "staffPendingLeave",
        "staffLeaveResponse",
      ]);
      const { getters_staff_pending_leave } = useGetters([
        "getters_staff_pending_leave",
      ]);

      const { results, loading, reason, observer, comment } = toRefs(data);
      const getStaffLeaveStatus = async () => {
        if (leaveStatus.value) {
          loading.value = true;
          staffPendingLeave({ status: leaveStatus.value }).then(() => {
            results.value = getters_staff_pending_leave.value;
            loading.value = false;
          });
        }
      };

      getStaffLeaveStatus();

      const getEventColor = (event) => event.color;

      const replyLeaveBtn = async (id) => {
        await observer.value.validate().then((result) => {
          if (result == false) return;

          loading.value = true;
          staffLeaveResponse({
            id: id,
            status: reason.value,
            feedback_comment: comment.value,
          })
            .catch(() => {
              comment.value = "";
            })
            .finally(() => {
              comment.value = "";
              loading.value = false;
            });
        });
      };

      return {
        ...toRefs(data),
        getEventColor,
        getStaffLeaveStatus,
        replyLeaveBtn,
      };
    },
  });
</script>

<style scoped>
  .v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
  }
</style>
